<template>
  <div>
    <loading
      :active="isLoading || isSendingRaas"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-4">
        <span class="text-gray-800 fs-6 text-start fw-bold ms-5 me-4"
          >Invia:</span
        >
        <div class="btn-group">
          <button
            class="btn btn-light-primary"
            @click="sendRaasSelezionati(stati_invio)"
          >
            Selezionati
          </button>
          <button
            class="btn btn-light-primary"
            style="border-left: 1px solid #009ef7"
            @click="sendRaasTutti(stati_invio)"
            :disabled="!stati_invio"
          >
            Tutti
          </button>
        </div>
      </div>
      <div class="col-4 fs-5 text-center align-self-center">
        <!-- <button
          type="button"
          class="badge bg-secondary rounded blueFit me-4 mb-1"
        >
          Allinea con modifiche
        </button> -->
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="results && results.length === 0 && loaded"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="results"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAffiliazioni"
        @current-change="setCurrentPageAffiliazioni"
        @checkboxClicked="
          checkAll = !checkAll;
          results.forEach((element) => {
            if (element.id_stato != 2) element.selected = checkAll;
          });
        "
        :checkedAll="checkAll"
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <input
            v-if="data.id_stato != 2"
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id_societa"
        /></template>
        <template v-slot:cell-cod_fiscale="{ row: data }"
          ><div class="">
            {{ data.codice_fiscale }}
          </div>
        </template>
        <template v-slot:cell-cod_affiliazione="{ row: data }"
          ><div class="">
            {{ data.codice_affiliazione }}
          </div>
        </template>
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">
            {{ data.denominazione }}
          </div>
        </template>
        <template v-slot:cell-ultimo_invio="{ row: data }"
          ><div class="">
            {{ getItTime(data.ultimo_invio) }}
          </div>
        </template>
        <template v-slot:cell-stato="{ row: data }"
          ><div class="">
            {{ data.stato }}
          </div>
          <i
            v-if="data.id_stato === 3"
            class="bi bi-info-circle text-dark fs-6 p-1 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#modal_errori_raas"
            @click="selectedIdErrorModal = data.id_societa"
          ></i>
        </template>
        <template v-slot:cell-note="{ row: data }"
          ><div class="">
            {{ data.note }}
          </div>
        </template>
      </Datatable>
    </div>
  </div>
  <ModalErroriRaas
    :id_societa="selectedIdErrorModal"
    link="RAAS_ERR_CONSIGLIO_DIRETTIVO"
  />
</template>

<script>
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import {
  approvaMovAffiliazione,
  deleteMovAffiliazione,
} from "@/requests/economatoAffiliazioniRequests";

import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useAddRaas } from "@/composables/addRaas";

import ModalErroriRaas from "@/components/components-fit/raas/ModalErroriRaas.vue";

import { getItTime } from "@/requests/raasAddRichiesta";

export default {
  name: "TableAffiliazioniEconomato",
  components: { Datatable, Loading, ModalErroriRaas },
  emits: ["getRaasList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Codice fiscale",
        key: "cod_fiscale",
      },
      {
        name: "Codice affiliazione",
        key: "cod_affiliazione",
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Ultimo Invio",
        key: "ultimo_invio",
      },
      {
        name: "Stato",
        key: "stato",
      },
      {
        key: "note",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRowsAffiliazioni = (e) => {
      store.commit("setFetchRowsRaasCons", e);
      emit("getRaasList");
    };
    const setCurrentPageAffiliazioni = (page) => {
      store.commit("setCurrentPageRaasCons", page);
      emit("getRaasList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnRaasCons", columnName);
      store.commit("setSortOrderRaasCons", order);
      emit("getRaasList");
    };

    //richiesta Api
    // const affiliazione = ref({});

    // const idAffiliazione = ref(null);
    // const setIdAffiliazione = async (id) => {
    //   idAffiliazione.value = id;
    // };

    const isLoading = ref(false);

    const checkAll = ref(false);

    const {
      sendRaasSelezionati,
      sendRaasTutti,
      refreshContent,
      isSendingRaas,
    } = useAddRaas("raas_consiglio_list", 2);

    watch(refreshContent, () => {
      emit("getRaasList");
    });
    const selectedIdErrorModal = ref(null);

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedraas_consiglio_list")
    );

    watch(loaded, () => {
      checkAll.value = false;
    });

    return {
      getItTime,
      selectedIdErrorModal,
      isSendingRaas,
      sendRaasSelezionati,
      sendRaasTutti,
      checkAll,
      isLoading,
      tableHeader,
      // affiliazione,
      // idAffiliazione,
      // setIdAffiliazione,
      setFetchRowsAffiliazioni,
      isEnabled,
      setCurrentPageAffiliazioni,
      setSortOrderColumn,

      results: computed(() =>
        store.getters.getStateFromName("resultsraas_consiglio_list")
      ),
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordraas_consiglio_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusraas_consiglio_list")
      ),
      currentPage: computed(() => store.getters.currentPage_raas_consiglio),
      rowsToSkip: computed(() => store.getters.rowtoskip_raas_consiglio),
      fetchRows: computed(() => store.getters.fetchrows_raas_consiglio),
      sortColumn: computed(() => store.getters.sortcolumn_raas_consiglio),
      sortOrder: computed(() => store.getters.sortorder_raas_consiglio),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      stati_invio: computed(() => store.getters.stati_invio_raas_consiglio),
    };
  },
};
</script>
<style scoped></style>
